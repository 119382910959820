<template>
  <div class="register publicVCenter">
    <img src="@/assets/image/phone/img4.png" class="bg" />
    <div class="advatage publicVCenter">
      <div class="item">互动收益80%，邀请收益12%</div>
      <div class="item">微信零钱自动到账</div>
    </div>
    <div class="registerForm publicVCenter">
      <div class="phoneInput">
        <el-input placeholder="手机号" v-model="phone" maxlength="11">
          <i slot="prefix" class="el-input__icon el-icon-mobile-phone"></i>
        </el-input>
      </div>
      <div class="passwordInput">
        <el-input
          placeholder="密码"
          v-model="password"
          show-password
          maxlength="20"
        >
          <i slot="prefix" class="el-input__icon el-icon-unlock"></i>
        </el-input>
      </div>
      <div class="inviteCodeInput">
        <el-input placeholder="邀请码" v-model="inviteCode" maxlength="20">
          <i slot="prefix" class="el-input__icon el-icon-user"></i>
        </el-input>
      </div>
      <div class="validateCodeInput publicVCenter">
        <el-input placeholder="短信验证码" v-model="valiCode" maxlength="20">
          <i slot="prefix" class="el-input__icon el-icon-lollipop"></i>
        </el-input>
        <div class="getValiCodeBtn" @click="getValidate('1')">
          {{ isCd ? cd : '获取验证码' }}
        </div>
      </div>
      <div class="registerBtn publicHvCenter" @click="register">立即注册</div>
      <div class="loginEntry" @click="toLogin">去登录</div>
    </div>
  </div>
</template>

<script>
import { register, sendCode } from '@/api/index';
import { HEADIMG } from '@/assets/constant/default';

let btnLock = false;

export default {
  name: 'phoneRegister',
  metaInfo: {
    title: '嗨喵悦动 - 嗨喵悦动登录_嗨喵悦动大屏互动',
    meta: [
      {
        name: 'keywords',
        content:
          '嗨喵悦动,嗨喵悦动大屏互动,嗨喵悦动官网,嗨喵悦动登录,微信墙,微信互动,现场互动,嗨现场',
      },
      {
        name: 'description',
        content:
          '嗨喵悦动是一款免费便捷炫酷的活动大屏幕现场互动软件，适用于企业年会、酒吧、婚礼、商业庆典峰会等活动现场，包括微信上墙送祝福、扫码签到、抽奖投票、摇一摇红包雨等有趣的大屏互动游戏，让活动更精彩！',
      },
    ],
  },
  data() {
    return {
      phone: '',
      password: '',
      inviteCode: '',
      valiCode: '',
      isCd: false,
      cd: 60,
      openId: '',
      ext: '',
      codeId: '',
    };
  },
  computed: {},
  created() {
    if (this.$store.state.inviteCode) {
      this.inviteCode = this.$store.state.inviteCode;
    }
  },
  mounted() {
    console.log(this.$store.state);
  },
  beforeDestroy() {},
  methods: {
    register() {
      if (!this.phone) {
        this.$message.error('请输入手机号!');
        return;
      }
      if (this.phone.length !== 11) {
        this.$message.error('手机号格式错误!');
        return;
      }
      if (!this.password) {
        this.$message.error('请输入密码!');
        return;
      }
      if (!this.inviteCode) {
        this.$message.error('请输入邀请码!');
        return;
      }
      if (!this.valiCode) {
        this.$message.error('请输入短信验证码!');
        return;
      }
      if (btnLock) {
        return;
      }
      btnLock = true;
      const params = {
        phone: this.phone,
        password: this.password,
        inviteCode: this.inviteCode,
        valiCode: this.valiCode,
        ext: this.ext,
        codeId: this.codeId,
        openId: this.openId,
      };
      if (this.$store.state.bindId) {
        params.bindId = this.$store.state.bindId;
      }
      if (this.$store.state.h5_openid) {
        params.h5_openid = this.$store.state.h5_openid;
      }
      register(params)
        .then((res) => {
          console.log(res);
          if (res.code === '500' || res.code === '400') {
            this.$message.error(res.errMsg);
          } else if (res.code === '200') {
            this.$router.push({
              path: '/phoneMain',
            });
            this.$store.commit('updateUserInfo', {
              userName: res.user_name,
              headImg: res.headimg_url || HEADIMG,
              phone: this.phone,
              token: res.token,
            });
          } else {
            this.$message.error('注册失败!');
          }
          btnLock = false;
        })
        .catch((err) => {
          console.log(err);
          btnLock = false;
        });
    },
    getValidate(pSource) {
      if (this.isCd) {
        return;
      }
      if (!this.phone) {
        this.$message.error('请输入手机号!');
        return;
      }
      if (this.phone.length !== 11) {
        this.$message.error('手机号格式错误!');
        return;
      }
      if (btnLock) {
        return;
      }
      btnLock = true;
      sendCode({
        phone: this.phone,
        source: pSource,
      })
        .then((res) => {
          console.log(res);
          if (res.code === '200') {
            this.ext = res.data.ext;
            this.codeId = res.data.codeId;
            this.$message({
              message: res.msg,
              type: 'success',
            });
            if (!this.isCd) {
              this.isCd = true;
              this.cd = 60;
              this.countDown();
            }
          } else {
            this.$message.error(res.msg);
          }
          btnLock = false;
        })
        .catch((err) => {
          console.log(err);
          btnLock = false;
        });
    },
    toLogin() {
      this.$router.push({
        path: '/phoneLogin',
      });
    },
  },
};
</script>
<style lang="less" scoped>
.register {
  position: relative;
  flex-direction: column;
  background-color: #ffffff;
  padding-top: 90vw;
  padding-bottom: 12vw;
  .bg {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .advatage {
    flex-direction: column;
    position: relative;
    .item {
      font-size: 4.2vw;
      font-weight: 400;
      color: #333;
      margin: 3vw 0;
    }
  }
  .registerForm {
    width: 80vw;
    height: 100%;
    flex-direction: column;
    .phoneInput {
      margin-top: 6vw;
      width: 100%;
    }
    .passwordInput {
      margin-top: 3vw;
      width: 100%;
    }
    .inviteCodeInput {
      margin-top: 3vw;
      width: 100%;
    }
    .validateCodeInput {
      margin-top: 3vw;
      width: 100%;
      justify-content: space-between;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
      .el-input::v-deep {
        width: 40vw;
      }
      .getValiCodeBtn {
        font-weight: 400;
        font-size: 4.2vw;
        color: #feb041;
      }
    }
    .el-input::v-deep {
      font-size: 4.8vw;
      .el-input__inner {
        height: 12vw;
        padding-left: 7vw;
        padding-right: 7vw;
        &:focus {
          border-color: #febd48;
        }
      }
      .el-input__icon {
        width: auto;
      }
      .el-input__clear {
        font-size: 4vw;
        margin-right: 2vw;
      }
    }
    .registerBtn {
      width: 78vw;
      height: 12vw;
      background: #2572e6;
      border-radius: 6.267vw;
      color: #fefdfb;
      font-weight: 500;
      font-size: 4.8vw;
      margin-top: 8vw;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
    }
    .loginEntry {
      color: #2572e6;
      font-size: 3.8vw;
      margin-top: 6vw;
    }
  }
}
</style>
